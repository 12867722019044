import React from "react"
import LayoutA5 from "../../components/layout-a5"
import EnrollStep from "../../components/enroll-step"
import enrollStyles from "./a5_enroll.module.css"

export default class A5EnrollStep extends React.Component {
  render() {
    const color='#0000ff'
    const src='/demos/themeA5/enroll'
    return(
      <LayoutA5 >
        <div className={enrollStyles.enrollContainer}>
          <EnrollStep color={color} src={src} />
        </div>
      </LayoutA5>
    )
  }
}